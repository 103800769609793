@import "_variables.scss";

.overlay {
  z-index: 1000;
  background: #00000040;
}
.content_container {
  position: relative;
  z-index: 1001;
  .unsaved-changes-modal-content {
    margin: 0 auto;
    background: white;
    width: var(--chakra-size-lg);
    max-height: var(--chakra-size-md);
    align-self: center;
    .unsaved-changes-modal-body-container {
      .unsaved-changes-modal-body {
        .unsaved-changes-modal-title {
          padding-left: 0;
        }
        .unsaved-changes-modal-text {
          font-size: $font-size-reg;
        }
      }
    }
  }
}
